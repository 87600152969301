/*
TODO:
  Fix the frames until issue
    Get rid of it?
      Use it as the time for the async canvas in the webcam?

  Add play/pause for main video
    Fit canvas around the play bar
      color the canvas to get pixel perfect?
*/

import './App.css';
import { Box } from '@mui/system';
import { Alert, Card, Paper, Snackbar, TextField, Typography, Checkbox, Chip, CardMedia } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';


import React, { useEffect, useState, useRef } from "react";
import * as faceapi from "face-api.js";
import ReactGA from "react-ga4"
import CookieBanner from 'react-cookie-banner/lib';
import Joyride from "react-joyride";
import Tooltip from '@mui/material/Tooltip';
import Cookies from 'js-cookie';

import Video from './Shayne Topp： The Musical.mp4'

function App() {
  const [video1, setVideo] = useState(null);
  // const [canvas, setCanvas] = useState(null);
  // const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [camera, setCamera] = useState(true);
  const [uniqueEmotion, changeUniqueEmotion] = useState(true)
  // const [lastEmotion, setLastEmotion] = useState("")
  
  const [modelIsLoaded, setModelIsLoaded] = useState(false);
  const [captureVideo, setCaptureVideo] = useState(false);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  // const [detections, setDetections] = useState(null);

  const [difficulty, setDiff] = useState(.9);
  const [answer, setAns] = useState([])
  const [selection, setSel] = useState("");
  const height = 480;
  const width = 640;
  const videoRef = useRef(null);
  const videoRef2 = useRef(null);
  const canvasRef = useRef(null);
  // const uniqueCheck = useRef(null);

  const [multChoice, setMultChoice] = useState(true);
  const [tracking, setTracking] = useState(false);
  const cookieName = "user-has-accepted cookiez"
  const [{run, steps}, setState] = useState({
    run: !Cookies.get(cookieName),
    steps: [
      {
        content: <h2>Welcome to EmotionLab, where you will test your ability to analyze and mimic emotions! If you want to get started, go ahead and press play on the video!</h2>,
        locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
        placement: 'center',
        target: 'body',
      },
      {
        target:"#selectFile",
        content:"Select a .mp4 file for analysis!"
      },
      {
        target: '#video',
        content: 'Once a file is selected the video should appear here! After so many ms you will be prompted on an emotion that passes the difficulty threshold.',
      },
      {
        // target:"#answers",
        target:"#demo-radio-buttons-group-label",
        // placement: "bottom",
        content:"This is where you will answer what emotion the video is showing. Once an emotion is detected that passes the threshold the answers will become enabled!"
      },
      {
        target:"#difficulty",
        content:"This is the difficulty threshold! The higher the easier, and the lower has more nuance."
      },
      {
        target:"body",
        content:"After you have answered correctly you will have a chance to mimic the emotion."
      },
      {
        target:"#cameraSelection",
        content:"However, if you decided to decline, this button allows you to change your mind!"
      },
      {
        target:"#emailAddress",
        content:"If you have any questions or concerns, please reach out to me!"
      }

    ]
  })
  
  function changeDifficulty(e){
    // await setDiff(e.target.value/100.0)
    localStorage.setItem("difficulty", e.target.value/100.0)
    // console.log("new difficulty: ", difficulty)
    if(tracking){
      ReactGA.event({
        category: 'Slider',
        action: 'Drag/Click',
        label: 'Difficulty slider'
      })
    }
  }
  function changeSelection(e){
    console.log(e)
    setSel(e.target.value)

  }
  const handleClick = () => {
    setOpen(true);
  };
  const handleClick2 = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };

  const turnOffCamera = () => {
    setCaptureVideo(false);
    setCamera(!camera);
  }

  const onlyUnique= (event) => {
    changeUniqueEmotion(event.target.checked);
  }
  // Load models on page load
  useEffect(() => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
      faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
      faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
      faceapi.nets.faceExpressionNet.loadFromUri("/models"),
    ]).then(async () => {
      // if (navigator.mediaDevices.getUserMedia) {
        localStorage.setItem("difficulty", .9);
        // var vid = document.getElementById("video");
        setVideo(videoRef);
        // videoRef.setAttribute('src', video1)
        // videoRef.play();
        videoRef.current.controls = true;

        setIsLoaded(true);

      
      setModelIsLoaded(true)
      const canvas = document.getElementById('canvas');
      const video = videoRef.current//document.getElementById('video');
        
        // Set the canvas size to match the video size
        if (canvas){
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight - 80;
            
        // Position the canvas and video elements using absolute positioning
      canvas.style.position = 'absolute';
      canvas.style.top = '0';
      canvas.style.left = '0';
      video.style.position = 'absolute';
      video.style.top = '0';
      video.style.left = '0';  
        
        
        // Get the container element and set its position to relative
      const container = document.getElementById('container');
      container.style.position = 'relative'; 
      console.log("models loaded");



    }
    });
  }, []);
  function submitAnswer(e){
    console.log(answer, selection);
    if (answer[0][0]!==selection){
      console.log("Wrong");
      setOpen2(true)
    } else{
      console.log("Right");
      console.log("This is the selection", selection)
      setOpen(true)
      setMultChoice(true)
      if (camera){
        startVideo();
      } else{
        document.getElementById("video").play()
        document.getElementById("video").controls = true;
      }
    }
  }

  function playTheFuckingVideo(){
    try{
      if(!document.getElementById('canvas')){
        console.log("addEvent");
        //const canvas = faceapi.createCanvasFromMedia(video.srcObject);
        // const canvas = faceapi.createCanvas(document.getElementById("video"));
        const canvas = faceapi.createCanvas(videoRef.current)
        const video = videoRef.current//document.getElementById("video")
        // video.append(canvas);
        console.log("This is the video: ", video)
        canvas.id = "canvas";
        canvas.style.position = "absolute"
        
        document.getElementById("container").append(canvas);
        // document.body.append(canvas);
        const displaySize= {width: width, height: height - 120};
        // const displaySize = { width: video.offsetWidth, height: video.offsetHeight-120 };
        // const displaySize = { width: video.videoWidth, height: video-120 };
        faceapi.matchDimensions(canvas, displaySize);
        // video.append(canvas);
        
        // setDetections(temp)

        setInterval(async () => {
            
        let thing = document.getElementById("video");

        let detections = await faceapi
        .detectSingleFace(thing, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceExpressions()
        if(detections){
          const resizedDetections = faceapi.resizeResults(
            detections,
            displaySize
          );
          // console.log("Getting canvas")
          var temp = canvas
          // canvas
            .getContext("2d")
          // temp.fillStyle = "#FF0000"
          // temp.fillRect(0,0,canvas.width, canvas.height-40)
          temp.clearRect(0, 0, canvas.width, canvas.height);
          
          faceapi.draw.drawDetections(canvas, resizedDetections);
          // console.log("Detections: ", detections.expressions)
          // faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
          if(detections && multChoice){
            // console.log(temp1, temp2)
            let emotion = detections.expressions;
            const max = Object.keys(emotion).reduce((a, v) => Math.max(a, emotion[v]), -Infinity);
            const result = Object.keys(emotion).filter(v => emotion[v] === max);
            // console.log("RESULT IS: ", result, Object.values(detections[0].expressions)[0]);
            // console.log("This is the lastAnswer", lastAnswer)
            const lastAnswer = localStorage.getItem("lastAnswer")

            const threshold = emotion[result]>localStorage.getItem("difficulty")
            const newAndUnique = ((result[0] != lastAnswer)&&uniqueEmotion)
            const notPaused = !videoRef.current.paused 
            // console.log("UNIQUE CHECK: ", uniqueCheck.current.checked);
            if ( ((threshold && newAndUnique) || (threshold && !uniqueEmotion)) && notPaused){
              videoRef.current.pause()
              videoRef.current.controls = false;
              console.log("Detections.landmarks._positions: ", detections.landmarks._positions);
              
              // console.log("Threshold: ", localStorage.getItem("difficulty"));
              // console.log("Result and max: ", result, max)
              // console.log(result)
              // console.log("Nav devices: ", navigator.mediaDevices.getUserMedia({video: {width: 300}}))
              // console.log("We are here!")
              // setLastAnswer(result[0])//.then(() 
              let form = document.getElementById("answers");
              form.removeAttribute("disabled");
              // setIsPlaying(!isPlaying);
              // console.log("Paused: ", document.getElementById('video').paused)
              // if (document.getElementById('video').paused){
              localStorage.setItem("lastAnswer", result[0])
              setMultChoice(false);
              setAns([result, max]);
              // console.log("This is the last answer: ", lastAnswer)
              // }
            }
          }
        }
        }, 100)//500)
        console.log("Event added");
      console.log("Attribute: ", document.getElementById("video"))

      }
    } catch(error){
      console.log("Error")
      console.log(error)
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      let buffer = e.target.result;
      // We have to convert the buffer to a blob:
      let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });

      // The blob gives us a URL to the video file:
      let url = window.URL.createObjectURL(videoBlob);
      // console.log(buffer);
      videoRef.current.src = url;
      // videoRef.current.onloadedmetadata = () => {
      //   detectFaces();
      // };
    };
    // reader.readAsDataURL(file);
    reader.readAsArrayBuffer(file);
    };

  //Webcam stuff
  const startVideo = () => {
    // try{
      setCaptureVideo(true);
      navigator.mediaDevices
        .getUserMedia({video: {width: 300}})
        .then(stream => {
          console.log("This is the stream: ", stream)
          let video2 = videoRef2.current;
          video2.srcObject = stream;
          video2.play();
        })
        .catch(err => {
          console.error("error:", err);
          setCaptureVideo(false);
          setCamera(!camera);
          document.getElementById("video").play();
          
          document.getElementById("video").controls = true;
          // console.log(document.getElementById("cameraState"))
          // setCaptureVideo(false);
        })  
    // }catch(error){
    //   console.log(error)
    // }
  }

  const handleVideoOnPlay = () => {
    if (camera && videoRef2.current){
      setInterval( async () => {
        if (canvasRef && canvasRef.current && captureVideo ) {
          canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(videoRef2.current);
          const displaySize = {
            width: width,
            height: height
          }
          faceapi.matchDimensions(canvasRef.current, displaySize);
          const detections = await faceapi.detectAllFaces(videoRef2.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
          const resizedDetections = faceapi.resizeResults(detections, displaySize);
          canvasRef && canvasRef.current && canvasRef.current.getContext('2d').clearRect(0,0, width, height);
          canvasRef && canvasRef.current && faceapi.draw.drawDetections(canvasRef.current, resizedDetections)
          canvasRef && canvasRef.current && faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
          canvasRef && canvasRef.current && faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections);
          // console.log("THIS IS THE ANSWER: ", answer);
          let emotion = detections[0].expressions;
          const max = Object.keys(emotion).reduce((a, v) => Math.max(a, emotion[v]), -Infinity);
          const result = Object.keys(emotion).filter(v => emotion[v] === max);
          console.log("Max and result: ", max, result)
          console.log("ANSWER IS:", answer)
          if(answer[0][0] == result){
            console.log("Correct emotion! percentage: ", answer[1])
            if (answer[1] < max + .1 && answer[1] > max - .1){
              console.log("Expression is correct")
              closeWebcam()
              document.getElementById("video").play()
              
              document.getElementById("video").controls = true;
              // setIsPlaying(!isPlaying)
            }
          }
        }
      }, 100)
    }
    
  }
  const closeWebcam = () => {
    videoRef2.current.pause();
    videoRef2.current.srcObject.getTracks()[0].stop();
    setCaptureVideo(false);
  }
  
  return (
    
    <div className="App">
      <CookieBanner
        message='We have cookies, scroll to not allow'
        onAccept={() => {
          console.log("Accepted!")
          setTracking(true)
          ReactGA.initialize('G-VSZS22LV7L');
          ReactGA.send('pageview')//, {page: window.location.pathname});
        }}
        cookie={cookieName}
        dismissOnScroll={true}
      />
      <Joyride
        steps = {steps}
        run = {run}
        hideCloseButton
        continuous
        showSkipButton
        showProgress
        styles = {{
          options: {
            zIndex: 1100,
          }
        }}
      />
      <Box sx={{ width: '100%' }}>
        <Stack
          padding={10}
           spacing={2}>
          <Stack 
          padding={3}
          height={400} justifyContent="center" spacing={12} direction={'row'}>
              <Stack item xs={12} sm={6} id="container">
                <CardMedia
                  id="video"
                  src={Video}
                  onPlay={playTheFuckingVideo}
                  ref={videoRef}
                  preload="none"
                  // src=""
                  component="video"
                  style={{zindex:0, 
                    // position:"fixed",
                    //  top:0, left: 0,
                    width: "640px", height: "480px" }}
                />
                {/* <video
                    id="video"
                    // src=""
                    controls
                    onPlay={playTheFuckingVideo}
                    // src="Shayne Topp： The Musical.mp4"
                    ref={videoRef}
                    // autoPlay={true}
                    playsInline
                    muted
                    style={{zindex:0, 
                      // position:"fixed",
                      //  top:0, left: 0,
                      width: "640px", height: "480px" }}
                  /> */}

              </Stack>
              <Stack item xs={12} sm={6} id="difficulty">
                {/* <Tooltip title="Adjust the difficulty, higher=easier" placement="top-start"> */}
                 
                <Typography>Difficulty</Typography>
                <Tooltip title="Adjust the difficulty, higher=easier" placement="top">
                 
                  <Slider 
                    orientation="vertical"
                    onChange={changeDifficulty}
                    defaultValue={90}
                    valueLabelDisplay="auto"
                    height={10}
                    step={10}
                    marks
                    min={10}
                    // min={60}
                    max={100}  
                  />
                  </Tooltip>
              </Stack>
              
            <Stack orientation="row">
              <div id="cameraSelection">
                <Typography>Turn off the camera</Typography>
                <Tooltip title="If already selected, deselect for camera access" placement="top">
                 
                  <Checkbox id="cameraState" checked={!camera} onChange={turnOffCamera}/>
                </Tooltip>
              </div>
              
          
                <div id="selectFile">
                  <Tooltip title="Select a file to play and analyze!" placement="top-start">
                    <input type="file" onChange={handleFileChange} />
                  </Tooltip>
                </div>
              <div>
                {/* <Typography>Only test next unique emotion</Typography> */}
                {/* <Checkbox checked={true} disabled={true}  */}
                  {/* onChange={onlyUnique} */}
                {/* /> */}
              </div>
            </Stack>
          </Stack>
          <br />
          {/* <div id="selectFile">
            <Tooltip title="Select a file to play and analyze!" placement="top-start">
              <input type="file" onChange={handleFileChange} />
            </Tooltip>
          </div> */}
      {
        captureVideo ?
          modelIsLoaded ?
            <div>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px'}}>
                <video ref={videoRef2} height={height} width={width} onPlay={handleVideoOnPlay} style={{borderRadius: '10px'}}/>
                <canvas ref={canvasRef} style={{postiion:'absolute'}}/>
              </div>
            </div>
            :
            <div>loading...</div>
          :
          <>
          </>
      }


          <FormControl id="answers" disabled={multChoice}>
            <FormLabel id="demo-radio-buttons-group-label">Emotion</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              onChange={changeSelection}
            >
              <FormControlLabel selected value="angry" control={<Radio />} label="angry" />
              <FormControlLabel value="disgusted" control={<Radio />} label="disgusted" />
              <FormControlLabel value="fearful" control={<Radio />} label="fearful" />
              <FormControlLabel value="happy" control={<Radio />} label="happy" />
              <FormControlLabel value="neutral" control={<Radio />} label="neutral" />
              <FormControlLabel value="sad" control={<Radio />} label="sad" />
              <FormControlLabel value="surprised" control={<Radio />} label="surprised" />
              {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
            </RadioGroup>
            <Tooltip title="Submit answer to see if correct!" placement='top'>
              <Button disabled={multChoice} onClick={submitAnswer}>
                Submit
              </Button>  
            </Tooltip>
          </FormControl>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" >
            <Chip label="cadmeup1@gmail.com" variant="outlined" id="emailAddress"/>
          </Stack>
        </Stack>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
          Correct!
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
        <Alert onClose={handleClose2} severity="error" sx={{width: '100%'}}>
          You are wrong, try again!
        </Alert>
      </Snackbar>
    
    </div>
  );
}

export default App;